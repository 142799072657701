import React, { useRef, useEffect, useState } from "react";
import videojs from "video.js"; // Import Video.js
import "video.js/dist/video-js.css"; // Import Video.js styles
import { useSelector, useDispatch } from "react-redux";
import {
  setHidePopupVideoPlayerList,
  setEndedVideoPlayerList,
  updatePlaytime,
  updatePlaytimeLastWeek,
  updatePlaytimeLastWeekSelected,
} from "../redux/exerciseVideos";
import {
  completeVideoPlayPercentage,
  minimumVideoPlayPercentage,
  updateFrequency,
} from "../constants/defaultValues";

const VideoPlayerListByteArkJs = ({
  day_number,
  video_number,
  selectedVDO,
  lastWeekVDO_click,
  lastWeekVDOAll,
  lastWeekStart,
  selectExerciseVideoLastWeek,
  isCurrentWeek,
  backupVideo
}) => {

  if (backupVideo) {

  }
  const dispatch = useDispatch();
  const videoRef = useRef(null); // Video element reference
  const playerRef = useRef(null); // Video.js player reference
  const exerciseVideo = useSelector((state) => state.exerciseVideos.exerciseVideo);
  const user = useSelector((state) => state.authUser.user);
  const all_exercise_activity = useSelector((state) => state.exerciseVideos.all_exercise_activity);

  const [videoEnded, setVideoEnded] = useState(false);
  const [videoCurrDuration, setVideoCurrDuration] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [prevPlayTime, setPrevPlayTime] = useState(0);



  const savedPlayTime = localStorage.getItem(`video-${day_number}-${video_number}-${lastWeekStart}-time`);



  useEffect(() => {
    // Initialize Video.js player if not already initialized
    if (!playerRef.current && videoRef.current) {
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        autoplay: false,
        preload: "auto",
      });

      playerRef.current.on("loadedmetadata", () => {
        if (savedPlayTime) {
          playerRef.current.currentTime(parseFloat(savedPlayTime)); // Fix here: Use playerRef.current
        }
        setVideoDuration(playerRef.current.duration());
      });

      playerRef.current.on("timeupdate", () => {
        setVideoCurrDuration(playerRef.current.currentTime());
      });

      playerRef.current.on("ended", () => {
        setVideoEnded(true);
      });

      playerRef.current.on("error", (error) => {
        console.error("Video.js error:", error);
      });
    }

    // Cleanup the player on component unmount
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  // อัปเดต source ของวิดีโอเมื่อ selectedVDO เปลี่ยน
  useEffect(() => {
    if (playerRef.current && selectedVDO) {
      setVideoEnded(false);
      dispatch(setEndedVideoPlayerList(false));
      // อัปเดต URL ของวิดีโอใหม่
      playerRef.current.src({
        src: selectedVDO.url,
        type: "application/x-mpegURL", // HLS type
      });

      // สั่งให้วิดีโอเริ่มเล่น
      playerRef.current.play().catch((error) => {
        console.error("Error trying to play the video:", error);
      });
    }
  }, [selectedVDO]); // อัปเดตทุกครั้งเมื่อ selectedVDO เปลี่ยน

  useEffect(() => {
    if (videoEnded) {
      dispatch(setEndedVideoPlayerList(true));
    }
  }, [videoEnded]);

  useEffect(() => {
    // เช็คว่าถ้าวิดีโอถูกเล่นเกือบจบ (99.9%) จะถูกนับว่าจบ
    if (videoCurrDuration / videoDuration >= 0.999) {
      setVideoEnded(true);
    }


    setPrevPlayTime(videoCurrDuration);
    if (videoCurrDuration / videoDuration >= 0.85) {
      updatePlayTime();
    }


    if (videoCurrDuration) {
      if (videoCurrDuration / videoDuration >= 0.85) {
        localStorage.setItem(`video-${day_number}-${video_number}-${lastWeekStart}-time`, 0);
      } else {
        localStorage.setItem(`video-${day_number}-${video_number}-${lastWeekStart}-time`, videoCurrDuration);
      }

    }
  }, [videoCurrDuration]);

  const updatePlayTime = () => {

    const tempExerciseVideoLastWeekSelect = [...selectExerciseVideoLastWeek];
    const tempExerciseVideoLastWeekAll = [...all_exercise_activity];
    tempExerciseVideoLastWeekSelect[day_number][video_number] = {
      ...tempExerciseVideoLastWeekSelect[day_number][video_number],
      play_time: videoDuration,
      duration: videoDuration,
    };
    tempExerciseVideoLastWeekAll.forEach((item, index) => {
      if (item.week_in_program === lastWeekStart) {

        tempExerciseVideoLastWeekAll[index].activities = JSON.stringify(tempExerciseVideoLastWeekSelect);

      }
    });





    /* if (!isCurrentWeek) { */ // เเบบเดิม  //updatePlayTime ของผู้ใช้ต่ออายุดูย้อนหลัง

    if (tempExerciseVideoLastWeekAll.length != lastWeekStart) {

      //updatePlayTime ของผู้ week ย้อนหลัง


      dispatch(
        updatePlaytimeLastWeekSelected(
          user.user_id,
          user.start_date,
          user.expire_date,
          day_number,
          video_number,
          videoDuration,
          videoDuration,
          tempExerciseVideoLastWeekAll,
          lastWeekStart
        )
      );
    } else {


      //updatePlayTime ของผู้ใช้ต่ออายุดูคลิปปัจจุบัน ไม่ได้ใช้เเล้ว
      // updatePlayTime ของผู้ week ปัจจุบัน 
      const tempExerciseVideo = [...exerciseVideo];
      tempExerciseVideo[day_number][video_number] = {
        ...tempExerciseVideo[day_number][video_number],
        play_time: videoDuration,
        duration: videoDuration,
      };

 

      dispatch(
        updatePlaytime(
          user.user_id,
          user.start_date,
          user.expire_date,
          day_number,
          video_number,
          videoDuration,
          videoDuration,
          tempExerciseVideo
        )
      );
    }
  };

  const handleVideoClose = () => {
    if (playerRef.current) {
      playerRef.current.pause();
      playerRef.current.dispose();
      playerRef.current = null;
    }
    dispatch(setHidePopupVideoPlayerList(true));
  };

  const startPlayNew = () => {
    // Reset the saved playtime in local storage
    localStorage.setItem(`video-${day_number}-${video_number}-${lastWeekStart}-time`, 0);

    if (playerRef.current) {  // Use playerRef.current instead of player
      playerRef.current.currentTime(0);  // Reset the playtime to 0
      playerRef.current.play();  // Start playback
    }
  };

  return (
    <div>
      <div data-vjs-player>
        <video id="videoPlayerList" ref={videoRef} className="video-js" playsInline />
        <div style={{ display: "flex", position: "absolute", bottom: "-60px", left: "24px" }}>
          <button type="button" class="box-random" onClick={() => startPlayNew()}>เริ่มใหม่</button>

        </div>
      </div>
      <img
        alt=""
        src="../assets/img/thumb/close.png"
        className="close"
        id="autoClickLink"
        onClick={handleVideoClose}
      />
    </div>
  );
};

export default VideoPlayerListByteArkJs;

